<template>
  <div class="home">
    <Else></Else>
    <div class="home-con">
      <div class="home-div1">
          <div class="title">云上品牌指导站</div>
          <div class="middle">
            <span class="middle-span1" @click="chose">
              {{searchtitle}}
              <i :class="bottomshow?'el-icon-caret-bottom':'el-icon-caret-right'"></i>
            </span>
            <input type="text" v-model="word" :placeholder="name">
            <span class="middle-span2" @click="search">检索</span>
          </div>
          <div class="middle-bottom" v-show="bottomshow">
            <div @click="add(1)">商标名</div>
            <div @click="add(2)">申请人</div>
          </div>
      </div>
      <div class="home-bottom">
          <div class="bottom1">
            <div class="bottom1-div1" @click="to1">
              <div>
                <img :src="require('/static/images/shangbiaopinggu.png')">
                <span>AI商标注册成功率评估</span>
              </div>
            </div>
            <div class="bottom1-div2" @click="to2">
              <div>
                <img :src="require('/static/images/guanli.png')">
                <span>商标管理</span>
              </div>
            </div>
          </div>
          <!-- <div class="bottom1">
            <div class="bottom1-div1 mm" @click="to3">
              <div>
                <img :src="require('/static/images/zhuanjia.png')">
                <span>专家库</span>
              </div>
            </div>
            <div class="bottom1-div2 mm" @click="to4">
              <div>
                <img :src="require('/static/images/pingpai.png')">
                <span>品牌故事</span>
              </div>
            </div>
          </div> -->
      </div>
    </div>
   
  </div>

</template>

<script>
import layer from "layui-layer";
import Else from '../ui/else.vue';
export default {
  name: 'home',
  data(){
    return {
      province:'',
      city:'',
      county:'',
      town:'',
      bottomshow:false,
      searchtitle:"商标名",
      searchType:1,
      word:'',
      name:'请输入商标名称'
    }
  },
  components: {
    Else
  },
  watch:{
    
    
  },
  methods: {
    tip(type, msg) {
      return this.$message({
        type: type,
        message: msg,
        showClose: true,
        duration: 1500,
      });
    },
    getQueryVariable(variable) {//获取路径参数
                let query = window.location.search.substring(1);
                let vars = query.split("&");
                for (let i = 0; i < vars.length; i++) {
                    let pair = vars[i].split("=");
                    if (pair[0] == variable) {
                        return pair[1];
                    }
                }
                return (false);
            },
        getbj() {
                let province = this.getQueryVariable('province');
                let city = this.getQueryVariable('city');
                let county = this.getQueryVariable('county');
                let town = this.getQueryVariable('town');
                if (province) {//初始化获取参数执行搜索
                    this.province = decodeURI(province)
                }
               if (city) {//初始化获取参数执行搜索
                    this.city = decodeURI(city);
                    if(this.city == "鞍山市"){
									localStorage.setItem('source', "ASZC")
								}else if(this.city == "温州市"){
									localStorage.setItem('source', "WZZC")
								}
                }
                if (county) {//初始化获取参数执行搜索
                    this.county = decodeURI(county)
                }
                if (town) {//初始化获取参数执行搜索
                    this.town = decodeURI(town)
                }
                localStorage.setItem('province', this.province)
                localStorage.setItem('city', this.city)
                localStorage.setItem('county', this.county)
                localStorage.setItem('town', this.town)
            },
            to1(){
              this.$router.push({
                  path:'/recomeSearch'
                })
            },
			// login() {
			// 	if (this.$store.getters.isLogin) {
			// 		this.$router.push({
			// 		  path:'/intellectualProperty',
			// 		  query:{
			// 		    company: this.$store.getters.applicant
			// 		  }
			// 		})
			// 	} else {
			// 		this.$router.push({
			// 		    path:'/login'
			// 		})
			// 	}				
			// },
            to2(){
                this.$router.push({
                  path:'/login',
                  query:{
                    province:this.province,
                    city:this.city,
                    county:this.county,
                    town:this.town
                  }
                })
            },
            //  to3(){
            //   this.$router.push({
            //       path:'/export'
            //     })
            // },
            // to4(){
            //   this.$router.push({
            //       path:'/famous'
            //     })
            // },
            chose(){
              this.bottomshow=!this.bottomshow
            },
            add(val){
              this.searchType= val;
              this.bottomshow = false
              if(val == 1){
                this.searchtitle="商标名"
                this.name= "请输入商标名称"
              }else{
                this.searchtitle="申请人"
                this.name= "请输入申请人名称"
              }
            },
            search(){
              if(this.word == ""){
                this.tip("error","请输入您的商标名称或申请人名称")
              }else{
                this.$router.push({
                  path:'/search',
                  query:{
                    word:this.word,
                    searchType:this.searchType
                  }
                  })
              }
            }
  },
   mounted() {
     this.getbj();
     window.parent.postMessage(
                  {
                    data: 721,
                  },
                  "*"
                );
    }
}
</script>

<style scoped>
html,body,#app{
    margin: 0;
    padding: 0;
    height: 100%;
}
.home-con{
  width: 100vw;
  height: 100vh;
  background: url('../../../static/images/bj.png') 100% no-repeat;
  background-size: cover;
  position: relative;
}
.home-div1{
  width: 900px;
  height: 255px;
  /* border:1px solid red; */
  position: absolute;
  top:45%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.title{
  width: 900px;
  margin: 0 auto 50px;
  text-align: center;
  color: #fff;
  font-size: 40px;
}
.middle-span1{
  display: inline-block;
  width: 140px;
  height: 60px;
  font-size: 20px;
  line-height: 60px;
  background: #D5DFF3;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
   color: #0931A1;
  /* margin-top: -1px; */
}
.el-icon-caret-right, .el-icon-caret-bottom{
  color: #0931A1;
  font-size: 20px;
}
.middle>input{
  width: 607px;
height: 58px;
padding-left: 9px;
vertical-align: middle;
outline: none;
/* color:#C8C8C8; */
font-size: 20px;
}
.middle-span2{
  display: inline-block;
  width: 140px;
height: 60px;
line-height: 60px;
text-align: center;
font-size: 20px;
color: #fff;
vertical-align: middle;
background: #FF913E;
cursor: pointer;
/* margin-top: -2px; */
}
.middle-bottom{
  width: 140px;
  height: 90px;
  background: #FFFFFF;
}
.middle-bottom>div{
  height: 44px;
  line-height: 44px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}
.middle-bottom>div:nth-of-type(1){
  border-bottom: 1px solid #D8D8D8;
}
.home-bottom{
  /* border:1px solid red; */
  width: 100vw;
  height: 100px;
  position: absolute;
  bottom: 0;
}
.bottom1>div{
  float: left;
  background: rgba(153,153,153,.6);
  backdrop-filter: blur(10px);
  height: 100px;
  border-bottom: 1px solid #FFFFFF;
  cursor: pointer;
  color: #FFFFFF;
  font-size: 18px;
  opacity: .8;
}
.bottom1-div1{
  width: calc(50vw - 1px);
  border-right: 1px solid #FFFFFF;
}
.bottom1-div2{
  width: 50vw;
}
.bottom1>div>div{
  width: 255px;
  margin: 20px auto 0;
  /* border:1px solid red; */
}
.bottom1>div>div>img{
  width: 64px;
  height: 64px;
  margin-right: 8px;
  vertical-align: middle;
}
.bottom1>div>div>span{
  vertical-align: middle;
}
.mm{
  border-bottom: none!important;
}
</style>


